<template>
  <b-card v-if="listMemberBenefitUsed.length || listBenefitUsed.length || avaiableMembershipDiscounts.length || hasAnOwnerBookingWithBenefits" class="height">
    <b-card-title @click="collapseBenefitCard = !collapseBenefitCard" class="m-0">
      <h4 class="d-flex justify-content-between" >{{ titleFivesClub }} <feather-icon :icon="collapseBenefitCard ? 'ChevronUpIcon' : 'ChevronDownIcon'" /></h4>
      <!-- <div class="mt-2" v-if="contractBenefitUsed">
        <h6 class="pb-0">
          <strong>Vivienda:</strong><br> {{ contractBenefitUsed }}
        </h6>
      </div> -->
    </b-card-title>

    <!-- <span v-if="!collapseBenefitCard && membershipDiscounts.length > 0">{{ membershipDiscounts.length }} descuentos de membresía aplicados <br></span>
    <span v-if="!collapseBenefitCard && listBenefitUsed.length > 0">{{ listBenefitUsed.length }} beneficios aplicados</span> -->

    <b-collapse id="accordeon-benefit-card" v-model="collapseBenefitCard" >

      <!-- Lista de beneficios por aplicar -->
      <div v-if="avaiableMembershipDiscounts.length && avaiableMembershipDiscounts.length > 0" class="mt-1">
        <!-- <hr> -->
        <b>Beneficios de membresía disponibles:</b>
        <label>Este descuento aplicará a los productos de la categoría sin descuento asignados</label>
        <b-row class="mt-half">
          <b-col
            v-for="discounts in avaiableMembershipDiscounts"
            :key="discounts.id"
            cols="6"
            @click="setSelectedMembershipDiscounts(discounts)"
          >
          <b-row class="text-left" align-v="center">
            <!-- <b-col class="text-center m-0" cols="1"><feather-icon :class="discounts.selected ? 'text-success' : ''" :icon="discounts.selected ? 'CheckCircleIcon' : 'CircleIcon'" size="14" /></b-col> -->
            <b-col class="m-0">
              <div :class="`custom-discount-padding border rounded ${discounts.selected ? 'benefit-selected-box' : 'benefit-box'} mt-half`">
                <label class="m-0">
                  <strong>{{discounts.categoryName}} </strong><br>
                  {{ discounts.discount }}% de descuento
                </label>
              </div>
            </b-col>
          </b-row>
          <!-- <div class="border-danger custom-discount-padding rounded"></div> -->
          </b-col>
        </b-row>
      </div>

      <!-- Lista de descuentos de membresía aplicados: -->
      <div v-if="membershipDiscounts.length && membershipDiscounts.length > 0" class="mt-1">
        <hr>
        <b>Lista de descuentos de membresía aplicados:</b>
        <table class="table table-sm table-striped mt-1" v-if="membershipDiscounts.length && membershipDiscounts.length > 0">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="custom-size">Descuentos</th>
              <th v-if="can('marketplace_sub_menu_show_marketplace_checkout_membership_action_button_delete')" scope="col" class="custom-w-benefits custom-size">acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, indice) in membershipDiscounts" :key="item.key">
              <td scope="row">
                <label><b>{{indice + 1}}:</b> <span class="font-weight-bold">{{ formatString(item.name) }}: </span><br> Descuento del {{item.discountApplied}}% </label>
              </td>
              <td scope="row"  v-if="can('marketplace_sub_menu_show_marketplace_checkout_membership_action_button_delete')" >
                <b-button variant="danger" size="sm" class=" float-right" @click="deleteDescountMember(item)" :disabled="loadvalid">
                  <div v-if="loadvalid" class="text-center ">
                    <b-spinner label="Loading..." small variant="success" />
                  </div>
                  <feather-icon v-else icon="TrashIcon" size="12" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Lista de beneficios aplicados -->
      <div v-if="hasAnOwnerBookingWithBenefits || (listBenefitUsed.length && listBenefitUsed.length > 0)"  class="mt-2">
        <hr>
        <b>Lista de beneficios aplicados:</b><br>
        <span v-if="cart.ownerbookingbenefits.active">
          <label class="textPago mt-1" v-if="hasAnOwnerBookingWithBenefits">
            Beneficio por usar en reservación: <strong>{{ choosedBenefit }}</strong> <br>
          </label>
          <div class="mt-2" cols="" v-else>
            <b>No cuenta con beneficios disponibles para usar en la reservación</b><br>
            <p class="textPago mt-1">Es posible que deba solicitar autorización más tarde para activar la reserva<br></p>
          </div>
        </span>
        <table class="table table-sm table-striped mt-1" v-if="listBenefitUsed.length && listBenefitUsed.length > 0">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="custom-size">Beneficios</th>
              <th scope="col" class="custom-w-benefits custom-size">acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, indice) in listBenefitUsed" :key="item.key" :class="item.show ? '':'d-none'">
              <td scope="row">
                <label><b>{{indice + 1}}:</b> <span class="font-weight-bold">{{ item.benfitname }}</span><br>({{ item.year }})</label>
              </td>
              <td scope="row" class="float-right">
                <b-button  variant="danger" size="sm" class="" @click="deleteDescount(item)">
                  <feather-icon icon="TrashIcon" size="12" />
                  <div v-if="loadvalid" class="text-center ">
                    <b-spinner label="Loading..." small variant="success" />
                  </div>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <pre v-for="(item, idx) in listBenefitUsed" :key="idx">
          {{item}}
          <span>
            {{ idx }}: {{ item.name }}
            show: {{ item.show }}
            keyRate: {{ item.keyRate }}
          </span>
        </pre> -->
        <!-- <pre v-for="(item, idx) in membershipDiscounts" :key="idx">{{ idx }}: {{ item }}</pre> -->
      </div>

    </b-collapse>
  </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import {showAlertMessage, toTitleCase} from "@/helpers/helpers"
import { acl } from "@/modules/auth/mixins/acl"
import { utils } from '@/modules/fivesClub/mixins/utils'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market, utils, acl],
  props: {
    listBenefitUsed: {
      type: Array,
      required: true,
    },

    listMemberBenefitUsed: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      loadvalid: false,
      selectedBenefit: null,
      showMembershipDiscounts: false,
      collapseBenefitCard: true,
      tableColumns: [
        { key:'benefitname', label: 'Nombre', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'balance', label: 'Disponible', formatter: (value, key, item) => {return item.benefit[key]}},
        { key:'used', label: 'Usado', formatter: (value, key, item) => {return item.benefit[key]}},
      ]
    }
  },
  computed: {
    ...mapState("shop", ["cart", "paymentFormat"]),
    ...mapGetters('shop',['totalBalance', 'selectedOwnerBookingBenefit', 'avaiableDiscounts']),
    membershipDiscounts(){
      const discounts = this.listMemberBenefitUsed.filter(discount => discount.modelType !== 'rates' || (discount.modelType === 'rates' && !!discount.show))
      return discounts
    },
    hasAnOwnerBookingWithBenefits(){
      return this.cart.ownerbookingbenefits.nightsContract || this.cart.ownerbookingbenefits.nightsXtra || this.cart.ownerbookingbenefits.hasBothNights
    },
    selectedBenefitName(){
      return this.selectedOwnerBookingBenefit?.name
    },
    contractBenefitUsed(){
      const contracts = this.cart?.contract
      if (contracts?.length) {
        const contract = contracts.find((contr) => contr.id === this.cart?.contractId)
        return contract ? `${contract?.housingname || ''} | ${contract?.membershipcode || ''}` : null
      } else return null
    },
    titleFivesClub(){
      if (this.listMemberBenefitUsed.length || this.listBenefitUsed.length) return 'Resumen de Beneficios'
      else if (this.contractBenefitUsed) return 'The Fives Club'
    },
    choosedBenefit(){
      const {nightsContract, nightsXtra, hasBothNights} = this.cart.ownerbookingbenefits
      if (hasBothNights) return this.selectedBenefitName || 'Por seleccionar'
      if (nightsContract) return 'Noches por Contrato'
      if (nightsXtra) return 'Noches Extra'
    },
    hasAvaiableBenefits(){
      const benefits = this.cart.benefits.filter(bn => bn.benfitcode != "RCOH" )
      return benefits.length > 0
    },
    avaiableMembershipDiscounts(){
      const currentDiscounts = this.avaiableDiscounts.map(disc => disc.category)
      return this.cart.membershipsDiscount.filter(mem => currentDiscounts.includes(mem.categoryId))
    }
  },
  methods:{
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations("shop", ["aplicateDescountResetUuid", "aplicateDescountReset", "deleteBenefitMember", "deleteBenefit", 'setDescountMember', "setDescountMemberDirect"]),
    async deleteDescount(item){
      this.loadvalid=true
      this.aplicateDescountResetUuid(item.uuid)
      this.deleteBenefit(item.uuid)
      this.setDescountMemberDirect(item.uuid)
      await this.calculateTotalWithDiscounts(this.product)
      this.paymentFormat.total = this.totalBalance
      // showAlertMessage("Notificación","BellIcon","📧 Se ha retirado el beneficio.","success",6000,"bottom-right")
      this.loadvalid=false
    },
    async deleteDescountMember(item){
      this.loadvalid=true
      if( item.modelType === "rates" ){
          const prodInCart = this.cart.items.filter( experiencies => experiencies.keyRate === item.keyRate )
          prodInCart.forEach(experience => {
            this.aplicateDescountResetUuid(experience.uuid)
            this.deleteBenefitMember(experience.uuid)
          })
      } else {
        this.aplicateDescountResetUuid(item.uuid)
        this.deleteBenefitMember(item.uuid)
      }
      await this.calculateTotalWithDiscounts(this.product)
      this.paymentFormat.total = this.totalBalance
      // showAlertMessage("Notificación","BellIcon","📧 Se ha retirado el descuento de membresía.","success",6000,"bottom-right")
      this.loadvalid=false
    },
    setSelectedMembershipDiscounts(discount){
      this.$emit('set-discounts-per-category', discount?.categoryId)
    },
    formatString(str) {
      return toTitleCase(str)
    },
    async reloadDiscounts(){
      this.$emit('reset-Benefit')
      this.setDescountMember()
      this.$emit('use-benefits')
      await this.calculateTotalWithDiscounts(this.product)
    }
  },
}
</script>

<style scoped>
.small-text{
  font-size: xx-small !important;
}
.margin-customer-card{
  margin: 0.17rem 0;
}
.custom-height{
  height: 3rem;
}
.content-card{
  margin-top: 1.53rem;
}
.height{
  height: fit-content;
}
.custom-size {
font-size: 11px !important;
}
.custom-discount-padding{
  padding: 0.6rem;
}
.benefit-space{
  margin: 0.3rem 0.01rem !important;
}
.benefit-selected-box{
  border: 1px solid #cdeddb !important;
  transition: all 0.5s ease;
}
.benefit-selected-box:hover{
  border: 1px solid #28c76f !important;
  transition: all 0.5s ease;
}
.benefit-box{
  border: 1px solid #dff4f5 !important;
  transition: all 0.5s ease;
}
.benefit-box:hover{
  border: 1px solid #333366 !important;
  transition: all 0.5s ease;
}
</style>