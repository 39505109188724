<template>
  <b-row>
    <b-col>
      <ValidationObserver ref="formularioCliente" tag="form" v-slot="{ invalid }">
        <b-form class="list-view" @submit.prevent="sendDataCliente" autocomplete="off">
          <b-card no-body>
            <b-card-header class="d-flex justify-content-between">
              <div>
                <b-card-title>Datos del cliente</b-card-title>
                <b-card-text class="text-muted mt-25">
                  Asegurese de llenar los campos que son obligatorios *
                </b-card-text>
              </div>
              <div v-if="existMainProduct">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="copyDataReservation"
                > <span class="mr-25 align-middle">📋 Copiar datos cliente principal</span>
                </b-button>
              </div>
            </b-card-header>
            <b-card-body>
              <b-row>
                <!-- <b-col md="12 text-right  mb-2">

                </b-col> -->

                <b-col cols="12" md="6"> <!-- Propietarios -->
                  <ValidationProvider
                    v-if="owners.length != 0 && cart.cliente.customertype == 2"
                    name="propietarios"
                    rules="required"
                  >
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Propietarios"
                    >
                      <b-form-select
                        :state="valid && !errors[0] && !!cart.cliente.email"
                        @change="sendInfoSelect(cart.cliente.email)"
                        v-model="cart.cliente.email"
                      >
                        <option value="">Seleccione propietario</option>
                        <option
                          v-for="owners in owners.owner"
                          :key="owners.idOwner"
                          :value="owners.ownerEmail"
                        >
                          {{ owners.ownerEmail }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="owners.length == 0 || cart.cliente.customertype != 2"
                    rules="required|email"
                    name="email"
                  >
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Email del cliente*"
                      class="mb-2"
                    >
                      <b-form-input
                        id="Email-del-cliente"
                        type="email"
                        autocomplete="off"
                        v-model="cart.cliente.email"
                        :state="valid && !errors[0] && !!cart.cliente.email"
                        placeholder="Ingrese email del cliente"
                        @input="checkIfEmailClientExist(cart.cliente.email)"
                        :disabled="listBenefitUsed.length > 0"
                      >
                      </b-form-input>

                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                  <b-row class="justify-content-center">
                    <b-col cols="7" md="6">
                      <b-button
                        v-if="cart.cliente.customertype == 2 && !ownerBookingInCart"
                        class="mr-1 mb-2 buttonOwner"
                        variant="primary"
                        @click="openModalOwner"
                      >
                        <feather-icon icon="SearchIcon" />Buscar Propietario
                      </b-button>
                    </b-col>
                    <b-col cols="5" md="6" class="text-right">
                      <b-button
                        v-if="showBenefitButton"
                        class="mr-1 mb-2 buttonOwner"
                        variant="primary"
                        @click="openModalBenefit"
                      >
                        <feather-icon icon="SearchIcon" />Beneficios
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" md="6"> <!-- Tipo cliente -->
                  <ValidationProvider name="Tipo cliente" rules="required">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Seleccione tipo cliente*"
                    >
                      <b-form-select
                        :state="valid && !errors[0] && !!cart.cliente.customertype"
                        @change="setTipoClienteInfo(cart.cliente.customertype)"
                        v-model="cart.cliente.customertype"
                        :disabled="listBenefitUsed.length > 0 || ownerBookingInCart"
                      >
                        <option value="">Seleccione tipo cliente</option>
                        <option
                          v-for="tpC in customerTypes"
                          :key="tpC.id"
                          :value="tpC.id"
                        >
                          {{ tpC.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6"> <!-- Nombre -->
                  <ValidationProvider rules="required" name="nombre">
                    <b-form-group slot-scope="{ valid, errors }" label="Nombre*">
                      <b-form-input
                        type="text"
                        v-model="cart.cliente.name"
                        :state="valid && !errors[0] && !!cart.cliente.name"
                        placeholder="Enter name customer"
                        @blur="setNameClient(cart.cliente.name)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6"> <!-- apellidos -->
                  <ValidationProvider rules="required" name="apellidos">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Apellidos*"
                    >
                      <b-form-input
                        type="text"
                        v-model="cart.cliente.lastname"
                        :state="valid && !errors[0] && !!cart.cliente.lastname"
                        placeholder="Ingrese los apellidos del cliente"
                        @blur="setLastNameClient(cart.cliente.lastname)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6"> <!-- Teléfono -->
                  <ValidationProvider
                    rules="required|min:10"
                    name="telefono"
                    vid="phone"
                  >
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Teléfono*"
                    >
                      <b-form-input
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        maxlength="20"
                        type="text"
                        autocomplete="off"
                        v-model="cart.cliente.phone"
                        :state="valid && !errors[0] && !!cart.cliente.phone && cart.cliente.phone.length >= 10"
                        placeholder="Ingrese el teléfono del cliente"
                        @blur="setPhoneCliente(cart.cliente.phone)"
                        @keypress="formatPhoneNumber"
                      >
                        <!-- @keypress="formatPhoneNumber" -->
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] || (!!cart.cliente.phone && cart.cliente.phone.length < 10 ? 'El campo telefono debe tener al menos 10 caracteres' : '')}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6"> <!-- Seleccione hotel -->
                  <ValidationProvider name="Hoteles" rules="required">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Seleccione hotel*"
                    >
                      <b-form-select
                        :state="valid && !errors[0] && !!cart.cliente.hotel"
                        @change="handleHotelClient(cart.cliente.hotel)"
                        v-model="cart.cliente.hotel"
                      >
                        <option selected :value="null">Seleccione hotel</option>
                        <option
                          v-for="hotel in showHotels"
                          :key="hotel.id"
                          :value="hotel.id"
                        >
                          {{ hotel.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6"> <!-- Seleccione idioma -->
                  <ValidationProvider name="Idioma" rules="required">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Seleccione idioma*"
                    >
                      <b-form-select
                        @change="setLanguageCliente(cart.cliente.language)"
                        :state="valid && !errors[0] && !!cart.cliente.language"
                        v-model="cart.cliente.language"
                      >
                        <option
                          v-for="lang in languages"
                          :key="lang.id"
                          :value="lang.id"
                        >
                          {{ lang.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <!-- Quien refiere -->
                <b-col cols="12" md="6"
                  v-if="cart.cliente.customertype == 4 &&
                  cart.cliente.customertype != ''"
                >
                  <ValidationProvider rules="" name="Nombre refiere">
                    <b-form-group
                      slot-scope="{ valid, errors }"
                      label="Nombre de quien refiere"
                    >
                      <b-form-input
                        type="text"
                        v-model="cart.cliente.customeragent"
                        :state="valid && !errors[0] && !!cart.cliente.customeragent"
                        placeholder="Quien refiere"
                        @blur="setNameAgente(cart.cliente.customeragent)"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
              <FormartPay
                :paymentMethods="user.fp"
                @delete-pay="deletePay"
                @delete-terminal="deleteTerminal"
                @sendPayFormat-set="managePaymentMethod"
                :disablePay="disablePay"
                :listBenefitUsed="listBenefitUsed"
                @validPayRoom-set="validPayRoom"
                @ResCred-set="evalua"
                @set-epayment="checkIfEpayment"
              />
              <b-row>
                <b-col class="d-flex">
                  <div class="mr-1">
                    <b-button
                      variant="success"
                      :disabled="invalid || cart.items == 0 || cannotQuote"
                      @click="openModalQuotes()"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-card-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                      </svg> Cotización
                    </b-button>
                  </div>

                  <div class="" v-if="!showResetButton">
                    <b-button
                      type="button"
                      variant="primary"
                      v-b-modal.modal-files
                    >
                      <feather-icon icon="FilePlusIcon"/> Archivos adjuntos
                    </b-button>
                    <b-modal id="modal-files" size="lg" hide-footer title="Add Docs" no-close-on-backdrop>
                      <ModalFiles @set-files="setFiles" @reset-files="resetFiles"/>
                    </b-modal>
                  </div>
                  <div class="" v-else>
                    <b-button
                      type="button"
                      variant="warning"
                      @click="resetFiles()"
                    >
                      <feather-icon icon="RefreshCcwIcon"/> Borrar archivos
                    </b-button>
                  </div>
                </b-col>
                <b-col>
                  <div class="float-right">
                    <b-button
                      v-if="bookingSinAuthorization > 0"
                      class="mr-1"
                      variant="primary"
                      @click="openModalAuthorizeBooking"
                    >
                      <feather-icon icon="UnlockIcon" /> Autorizar bookings
                    </b-button>
                    <b-button
                      v-if="orderCreated"
                      :disabled="invalid || cart.items == 0"
                      variant="danger"
                      class="mid-buttons custom-margin"
                      @click="cancelarOrden"
                    >
                      <b-icon icon="x-circle-fill"></b-icon> Cancelar
                    </b-button>
                    <b-button v-if="orderCreated"
                      :disabled="invalid || cart.items == 0"
                      variant="primary"
                      class="mid-buttons"
                      type="submit"
                    >
                      <b-icon icon="cash"/> Reintentar pago
                    </b-button>
                    <b-button v-else
                      :disabled=" invalid || cannotPay"
                      variant="primary"
                      type="submit"
                      class="mid-buttons"
                    >
                      <b-icon icon="cash" v-if="!isLoadingProcess" />
                      <b-spinner small v-if="isLoadingProcess" />
                      {{ atributosBtnPay.textBtn }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </ValidationObserver>
      <!-- Modal cargo habitación -->
      <RoomCharge
        :nameHotel="nameHotel"
        :cliente="cart.cliente"
        @reset-customer-form="resetCustomerForm"
        @reset-room-charge-data='resetRoomChargeData'
      />
      <TabQuote @reset-customer-form="resetCustomerForm" />
      <ModalAuthorize />
      <ModalOwner
        @set-owners="sendInfo"
        @reset-every-Benefit="resetEveryBenefitDiscounts"
      />
      <ModalBenefit
        ref="ModalBenefit"
        :setUseBenefit="sendInfoMemberDescount"
        :getBenefits="getdiscountsPerContract"
        :resetEveryBenefit="resetEveryBenefitDiscounts"
      />
    </b-col>
    <b-col md="4" class="margin-list">
      <CustomerCard
        :cart="cart"
        :nameHotel="nameHotel"
        :tipoCliente="tipoCliente"
      />
      <BenefitsCard
        ref="benefitCard"
        :listBenefitUsed="listBenefitUsed"
        :listMemberBenefitUsed="listMemberBenefitUsed"
        @reset-payment-form="resetpayment"
        @use-benefits="useBenefit"
        @reset-Benefit="resetBenefit"
        @set-discounts-per-category="setDiscountsPerCategory"
      />
    </b-col>
  </b-row>
</template>

<script>
//eslint-disable-line
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import {
  validateDataInCart,
  checkItemsCartSihotCode,
  showAlertMessage,
  sortalphabetically,
  formatProducList,
  toJson
} from "@/helpers/helpers"

import TabQuote from "@/modules/shop/components/orderSummary/TabQuote"
import RoomCharge from "@/modules/shop/components/checkout/RoomCharge"
import CustomerCard from "@/modules/shop/components/checkout/CustomerCard"
import ModalAuthorize from "@/modules/shop/components/orderSummary/ModalAuthorize"
import FormartPay from "@/modules/shop/components/checkout/FormartPay"
import ModalOwner from "@/modules/shop/components/checkout/ModalOwner"
import ModalBenefit from "@/modules/shop/components/checkout/ModalBenefit"
import ModalFiles from "@/modules/shop/components/checkout/ModalFiles"
import BenefitsCard from '@/modules/shop/components/checkout/BenefitsCard'

export default {
  async created() {
    if (this.useCredit == true) this.cart.cliente.credit = 0
    await this.getInitialContentCheckout()
    if(this.cart.contractId) {
      await this.getdiscountsPerContract(this.cart.contractId)
      // await this.sendInfoMemberDescount(this.cart.contractId)
      if (this.cart.items.some(item => !!item.isBenefitDescout) && this.isUsingOwnerbenefit) {
        const descountsCalculated = await this.benefitDiscountManager({
          contractId: this.cart.contractId,
          products: this.cart.items.filter(pr=>pr.categoryName != "ReservasOwners" && !!pr.isBenefitDescout)
        })
      }
    }

    this.cart.file = null
    delete this.cart.file
  },
  async mounted() {
    if (this.cart.cliente.id) {
      const hasAContract = this.checkIfAlreadyHasAContract(this.cart.cliente)
      if (!hasAContract) await this.checkIfClient(this.cart.cliente)
    }
    this.checkIfEpayment()
    this.paymentFormat.total = this.totalBalance
    // actualizo el id de paymentFormat para que se mantenga una forma de pago seleccionada en el input Método de pago*
    this.setIdPaymentFormat()

  },
  components: {
    // vSelect,
    //SFC
    TabQuote,
    RoomCharge,
    CustomerCard,
    ModalAuthorize,
    FormartPay,
    ModalOwner,
    ModalBenefit,
    ModalFiles,
    BenefitsCard
  },
  data() {
    return {
      disabledBtnCharge: true,
      roomChargeTotal: "0",
      balancePay: 0,
      disablePay: false,
      isOwner:false,
      isLoadingBenefit: false
    };
  },
  watch: {
    cart: {
      deep: true,
      async handler() {
        this.checkIfEpayment() // Detecto cualquier cambio en el carrito para ejecutar esta función
        this.paymentFormat.total = this.totalBalance
      },
    },
    itemsAmount: {
      async handler(){
        if (this.itemsAmount == 0) { // Vacío todo el carro de compras
          this.resetAllBenefitsProductsDiscounts()
          this.setCurrentContractBenefitsList([])
          this.resetBenefit()
          this.resetBenefitMember()
          this.setInfoClient(null)
          this.clearHeadersCliente()
          this.clearOwnerInfo()
          this.resetpayment()
          this.setOwners(null)
          this.setContract("")
          this.setEmptyCart()
          this.$router.push({ name: 'sales'})
        }

        if (!!this.cart.contractId) {
          await this.getdiscountsPerContract(this.cart.contractId)
        }
        const ownerBookingInCart = this.cart.items.some(items => items.categoryName == "ReservasOwners" )
        if (!ownerBookingInCart) this.deleteOwnerBookingCartData()

      }
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("shop", [
      "benefits",
      "cart",
      "dataListDescriAmenities",
      "dataListDescriQuoted",
      "dataListDescriQuotedFired",
      "dataListDescriTerms",
      "errors",
      "isLoadingProcess",
      "listBenefitUsed",
      "listMemberBenefitUsed",
      "orderCreated",
      "owners",
      "paymentFormat",
      "quoted",
      "validQuoted",
    ]),
    ...mapState("start", [
      "customerTypes",
      "hotels",
      "languages",
      "paymentMethods",
      "useCredit",
    ]),
    ...mapGetters("shop", [
      "totalBalance",
      "totalCredit",
      "totalDiscountCuenta",
      'subTotalParcial',
      'subTotalParcialBooking',
      'totalDiscount',
      'tc',
      "currencyTotal",
      "currencySubTotal",
      "currencyTotalDiscount",
      "avaiableDiscounts"
    ]),
    bookingSinAuthorization() {
      const cart = this.cart.items;
      return cart.filter((b) => b.modelType === "booking" && b.requiresAuthorization).length
    },
    nameHotel() {
      const hotel = this.hotels.find((h) => h.id === this.cart.cliente.hotel);
      return hotel ? hotel.name : "";
    },
    tipoCliente() {
      const tipoCliente = this.customerTypes.find((tc) => tc.id === this.cart.cliente.customertype)
      return tipoCliente ? tipoCliente.name : ""
    },
    datosMetodoPagoForSihot() {
      const idFormaPago = this.cart.cliente.paymentmethod;
      const formaDePago = this.user.fp.find((fp) => fp.id === idFormaPago)
      return formaDePago ? formaDePago.interfazsihot || false : false;
    },

    atributosBtnPay() {
      const textBtn = this.isLoadingProcess ? "Procesando Venta" : "Finalizar Venta"
      const disabledBtn = this.isLoadingProcess ? true : false

      return { textBtn, disabledBtn }
    },
    existMainProduct() {
      const booking = this.cart.items.find((item) => item.isMain == true)
      const producto = this.cart.items.find((item) => item.isMainProduct == true)
      return booking || producto
    },
    ownersInfo() {
      const owner = this.owners.owner.find((o) => o.ownerEmail === this.cart.cliente.email)
      return owner ? owner : ""
    },
    showHotels() {
      return sortalphabetically(this.hotels)
    },
    itemsAmount(){
      return this.cart.items?.length
    },
    showBenefitButton(){
      return this.cart.contract && this.cart.contract.length > 0 && this.cart.cliente.customertype == 2
    },
    ownerBookingInCart(){
      return this.cart.items.some(items => items.categoryName == "ReservasOwners" )
    },
    validateOwnerBookingBenefits(){
      return this.ownerBookingInCart && (this.cart.ownerbookingbenefits?.nightsContract == this.cart.ownerbookingbenefits?.nightsXtra)
    },
    cannotPay(){
      const {items, cliente} = this.cart

      return items?.length == 0 || this.atributosBtnPay?.disabledBtn || cliente.paymentList?.length == 0 || this.paymentFormat?.total > 0 || this.totalBalance > 0 || this.validateOwnerBookingBenefits
    },
    cannotQuote(){
      const {items, cliente} = this.cart
      return items == 0 || this.atributosBtnPay?.disabledBtn || cliente.paymentList?.length == 0
    },
    canUseBenefit(){
      //si tipo de cliente es propietario
      const customerTypeIsOwner = this.cart.cliente.customertype == 2 // cliente propietario

      const paymentMethod = this.user.fp.find((fp) => fp.id === this.paymentFormat.id)
      const { isownersPayment, credittouse, name, id} = paymentMethod
      const paymentMethodIsOwnersBenefit = ( name.toUpperCase() === 'OWNERS BENEFIT' || id === 1055 )

      const creditToUseIsZero = credittouse === '0' // is zero
      const canUseBenefit = ( paymentMethodIsOwnersBenefit && creditToUseIsZero && isownersPayment && customerTypeIsOwner )
      return canUseBenefit
    },
    showResetButton(){
      return !!this.cart.files
    },
    isUsingOwnerbenefit(){
      const formaPagoPropietario = this.user.fp.find((fp) => fp.isownersPayment === true)
      const formaPagoPropietarioEnCart = this.cart.cliente.paymentList?.some(fpo => fpo.id == formaPagoPropietario?.id)
      return formaPagoPropietarioEnCart
    },
    validateOwnerPayment(){
      const ownerbooking = this.cart.items.find(item=>item.categoryName=='ReservasOwners')

      if (this.listBenefitUsed?.length > 0 || ownerbooking) return this.isUsingOwnerbenefit
      else return true
    },
  },
  methods: {
    ...mapActions("shop", [
      "calculateTotalWithDiscounts",
      "cancelOrder",
      "checkIfClientExists",
      "fetchListDescriQuoted",
      "fetchListDescriQuotedFired",
      "fetchsetListDescriAmenities",
      "fetchsetListDescriTerms",
      "getInitialContentCheckout",
      "sendInfoClient",
      "benefitDiscountManager"

    ]),
    ...mapActions("fivesClubCatalogs", [
      "fetchBenefitsProductsShop",
      "getContractOwner",
      "getContractOwner",
      "getContractUser",
      "getdiscountsContract",
      "fetchBenefitsByYearContract"
    ]),
    ...mapMutations("auth", ["setUser"]),
    ...mapMutations("start", ["setPaymentMethods"]),
    ...mapMutations("shop", [
      'clearHeadersCliente',
      "addFiles",
      "addPaymentClient",
      "addPaymentClientList",
      "balancePayCredit",
      "clearOwnerInfo",
      "clearPaymentList",
      "copyDataMainItem",
      "deleteFiles",
      "deleteOwnerBookingCartData",
      "deletePaymentMethodClient",
      "deletePaymentTerminal",
      "resetAllBenefitsProductsDiscounts",
      "resetPay",
      "setBenefits",
      "setContract",
      "setContractEmail",
      "setCurrentContract",
      "setCurrentContractBenefitsList",
      "setDescount",
      "setDescountMember",
      "setDescountMemberDirect",
      "setEmptyCart",
      "setEpayment",
      "setHotelCliente",
      "setIdPaymentFormat",
      "setInfoClient",
      "setLanguageCliente",
      "setLastNameClient",
      "setMembershipsDiscount",
      "setNameAgente",
      "setNameClient",
      "setOwners",
      "setPaymentMethodClient",
      "setPhoneCliente",
      "setReferenceClient",
      "setResortCredit",
      "setResortCreditOne",
      "setSihotCodeHotel",
      "setTipoCliente",
      "setValidNull",
      "unsetDataRoom",
      "unsetLastNameSearch",
      "unsetMakeRoomCharge",
    ]),

    // # Datos del Cliente
    async handleHotelClient(hotel) {
      const cliente = JSON.parse(JSON.stringify(this.cart.cliente));
      const fpUsuario = JSON.parse(JSON.stringify(this.user.fp));
      const fPago = JSON.parse(JSON.stringify(this.user.fpBackup));
      //si hotel es downtown y forma de pago es roomcharge
      if (hotel == 2) {
        if ( cliente.paymentmethod === 4 ){
          this.setPaymentMethodClient("")
          this.clearPaymentList([])
        }
        const sinRoomCharge = fpUsuario.filter((fp) => fp.id !== 4)
        this.user.fp = sinRoomCharge
        await this.setUser(this.user)
      } else {
        this.user.fp = fPago
        await this.setUser(this.user)
      }
      this.setHotelCliente(hotel)
      if (!!hotel) {
        const hotelSihot = this.hotels.find((hotel) => hotel.id === this.cart.cliente.hotel)
        this.setSihotCodeHotel(hotelSihot?.sihotCode)
      }
    },
    async checkIfEmailClientExist(email) {
      //eslint-disable-next-line
      const reg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      if (reg.test(email)) {
        const client = await this.checkIfClientExists(this.cart.cliente)
        if (client?.name != null) {
          if (client?.status) {
            const isConfirmed = await this.confirmSaving(client)
            if (isConfirmed) {
              this.setInfoClient(client)
              await this.checkIfClient(client)
              showAlertMessage("Notificación","BellIcon","📧 Los datos del cliente con este correo han sido actualizados.","success",6000,"bottom-right")
            } else this.setTipoClienteInfo(this.cart?.cliente?.customertype || '')
          } else {
            this.setInfoClient(null)
            this.setCurrentContractBenefitsList([])
            this.setCurrentContract(null)
            this.clearOwnerInfo()
            this.resetAllBenefitsProductsDiscounts()
            await this.resetEveryBenefitDiscounts()
            return showAlertMessage("No se puede usar este cliente","BellIcon","😓 El cliente seleccionado no está activo para ventas.","warning",6000,"bottom-right")
          }
        }
      }
    },
    async checkIfClient(client) {
      //eslint-disable-next-line
      if (client) {
        const ownerBookingFind = this.cart.items.find((category) => category.categoryName === "ReservasOwners")
        const contracts = ownerBookingFind ? [ownerBookingFind?.contractData] : await this.getContractUser(client.id)
        this.setContractEmail(contracts)

        if(contracts?.length > 0 ) {
          this.resetAllBenefitsProductsDiscounts()
          this.isOwner=true
          const firstContract = contracts?.find(item => item.id == ownerBookingFind?.contract) || contracts[0]

          if (contracts?.length == 1 && firstContract) this.$refs['ModalBenefit'].sendInfo(firstContract)
          else if (contracts?.length > 1) {
            if (!!this.cart.contractId && contracts.some(contract => contract.id == this.cart.contractId)) this.$refs['ModalBenefit'].sendInfo(contracts.find(contract => contract.id == this.cart.contractId))
            if (this.listBenefitUsed?.length == 0 && this.listMemberBenefitUsed?.length == 0) this.openModalBenefit()
          }

        } else this.isOwner=false

        this.setInfoClient(client)
      }
    },
    async confirmSaving(client) {
      const { isConfirmed } = await this.$swal.fire({
        title: `<p style="font-size:.7em;">Este correo ya existe para el cliente: <br> ${client?.name || ''} ${client?.lastname || ''} </p>`,
        text: `¿Desea ocupar la información del cliente existente?`,
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "!Sí, ocuparlo!",
      });
      return isConfirmed;
    },

    // # Finalizacion de la venta
    async sendDataCliente() {
      //valida que ningún item tenga campos obligatorios sin llenar
      const ItemRequireFillFields = validateDataInCart(this.cart.items);
      if (!ItemRequireFillFields) {
        if (this.cart.cliente.customertype==2){
          // Valido que si el cliente es un propietario y existe un beneficio aplicado tenga aplicada owner benefit como método de pago
          const hasBenefitsAndValidPaymentMethod = this.validateOwnerPayment
          if (!hasBenefitsAndValidPaymentMethod) return showAlertMessage("Método de pago inválido","InfoIcon","Por favor, revise si cuenta con el método de pago para beneficio de propietarios añadido.","danger", 4000 ,"bottom-right")
          if (this.validateOwnerBookingBenefits) return showAlertMessage("No se detectó beneficio por usar","InfoIcon","Por favor, revise si seleccionó un beneficio para aplicar en la reservación.","danger", 4000 ,"bottom-right")
        }
        // verifico que los productos tengan codigo de sihot, si alguno no tiene, habilita booleano de cargo manual al tener room charge como método de pago
        const respuesta = checkItemsCartSihotCode(this.cart.items)
        const { ok, objetos } = respuesta
        //si en forma de pago interfazsihot es true, se aplica room charge
        const showInterfazSihot = this.datosMetodoPagoForSihot
        if (showInterfazSihot) {
          // si es cargo a la habitación y no hay código de sihot, no se muestra la ventana
          if (ok) {
            let service = []
            objetos.forEach((item) => {
              const producto =  item.name || '' +' - '+item.detaildisplay
              service.push(producto)
            })
            return showAlertMessage(
              "No se pudo hacer cargo a habitación", "BellIcon",
              `Hay ${service.length > 1 ? 'varios productos' : 'un producto'} sin código de sihot, por lo cual no se puede realizar el cargo automatico: \n ${service} \n`,
              "warning", 5000, "bottom-right"
            )
          } else {
            this.resetRoomChargeData()
            this.$root.$emit("bv::show::modal", "modal-room-charge")
          }
        } else {
          await this.sendInfoClient(this.cart.cliente)
          if (this.cart.items) this.resetCustomerForm() //reseteo validacion veevalidate
          // showAlertMessage("Simulacro terminado","InfoIcon","La venta ha finalizado.","success", 4000 ,"bottom-right")
        }
      }
    },
    async cancelarOrden() {
      await this.cancelOrder(this.orderCreated);
      this.resetCustomerForm(); //reseteo validacion veevalidate
    },
    managePaymentMethod(){
      const paymentmethod = this.user.fp.find(payment=> payment.id === this.paymentFormat?.id)
      const hasAnOwnerBooking = this.cart.items.some(items => items.categoryName == "ReservasOwners")

      if (paymentmethod?.isownersPayment && !hasAnOwnerBooking) {
        if (this.cart.benefits.filter(benefit => benefit.benfitcode != "RCOH").length == 0) return showAlertMessage( 'Aviso', 'InfoIcon', 'No cuenta con beneficios suficientes para aplicar esta forma de pago', 'warning', 4000, 'bottom-right')
      }

      if (this.canUseBenefit) this.useBenefit(this.cart.contract)
      else this.sendPayFormat()
    },
    // inicia sendPayFormat
    sendPayFormat() {

      this.disablePay = true;
      const idFormaPago = this.paymentFormat.id;
      const formaPagoNombre = this.user.fp.find((fp) => fp.id === idFormaPago)

      this.paymentFormat.total = (Math.round(this.paymentFormat.total * 100) / 100).toFixed(2)
      let monto = 0;
      let saldoCr = 0;
      let t1 = 0;
      let validFormaPagoId = "";
      if (parseFloat(this.totalBalance) < parseFloat(this.paymentFormat.total)) showAlertMessage(`Alerta`, "BellIcon", `No se puede aplicar la forma de pago `, "warning", 6000, "bottom-right",)

      if (formaPagoNombre.unicopago == true || this.cart.firstPay != 0) this.paymentFormat.total = this.totalBalance

      // Inicio del armado del payload con datos y condiciones
      var payload = {
        id: formaPagoNombre.id,
        name: formaPagoNombre.name,
        amount: this.paymentFormat.total,
        reference: this.paymentFormat.reference,
        order: formaPagoNombre.principal ? 1 : 0,
        credittouse:formaPagoNombre.credittouse
      };

      if (this.cart.cliente.paymentList.id == 7) payload = {
          cc_bank: this.paymentFormat.cc_bank,
          cc_type_bank: this.paymentFormat.cc_type_bank,
          auth_bank: this.paymentFormat.auth_bank,
        };

      if (formaPagoNombre.unicopago == true && this.cart.cliente.paymentList.length == 1) payload = ""

      if (this.cart.cliente.paymentList.length == 1) payload = {
          id: formaPagoNombre.id,
          name: formaPagoNombre.name,
          amount: this.totalBalance,
          reference: this.paymentFormat.reference,
          order: formaPagoNombre.principal ? 1 : 0,
          credittouse:formaPagoNombre.credittouse
        }

      let validFormaPrincipal = this.cart.cliente.paymentList.find((fp) => fp.order === true)

      if (validFormaPrincipal === undefined) validFormaPrincipal = false
      else validFormaPrincipal = true

      const validBalance = parseFloat(this.totalBalance);
      //inicia procedimiento de credito y formas de pago con beneficios
      if ( this.cart.cliente.paymentList.length <= 1 && this.totalBalance >= 0 && validBalance >= this.paymentFormat.total && formaPagoNombre.credittouse==0 &&  formaPagoNombre.type!="3") {
        validFormaPagoId = this.cart.cliente.paymentList.find((fp) => fp.id === this.paymentFormat.id)
        if ( validFormaPrincipal == payload.order && validFormaPrincipal != false) {
          payload = ""
          showAlertMessage(`Alerta`, "BellIcon", `No se puede aplicar la forma de pago `, "warning", 6000, "bottom-right",)
        }

        this.addPaymentClientList(this.cart.cliente.paymentList);

        if (validFormaPagoId != undefined) showAlertMessage(`Alerta`, "BellIcon", `No se puede duplicar forma de pago`, "warning", 6000, "bottom-right",)
        else {
          if (formaPagoNombre.unicopago == true &&
          this.cart.cliente.paymentList.length == 1) showAlertMessage(`Alerta`, "BellIcon", `No se puede aplicar la forma de pago `, "warning", 6000, "bottom-right",)
          else {
            if (payload != "") {
              this.addPaymentClient(payload);
              this.addPaymentClientList(this.cart.cliente.paymentList);
              this.balancePayCredit(formaPagoNombre);
            }
          }
        }
      } else {
        if ( parseFloat(this.cart.cliente.credit) != 0.0 && parseFloat(this.cart.cliente.credit) > parseFloat(this.paymentFormat.total)) {
          saldoCr = parseFloat(this.cart.cliente.credit) - parseFloat(this.paymentFormat.total)

          payload = {
            id: formaPagoNombre.id,
            name: formaPagoNombre.name,
            amount: this.paymentFormat.total,
            reference: this.paymentFormat.reference,
            order: formaPagoNombre.principal ? 1 : 0,
              credittouse:formaPagoNombre.credittouse
          }
          validFormaPagoId = this.cart.cliente.paymentList.find((fp) => fp.id === this.paymentFormat.id)
          if (validFormaPagoId != undefined) showAlertMessage(`Alerta`, "BellIcon", `No se puede duplicar forma de pago`, "warning", 6000, "bottom-right",)
          else {
            if (
              this.cart.cliente.paymentList.length >= 1 &&
              parseInt(this.totalBalance) >= 0 &&
              parseFloat(this.cart.cliente.credit) == 0.0
            ) {
              payload = "";
              this.paymentFormat.total = 0.0
              showAlertMessage(`Alerta`, "BellIcon", `😡 El pago no puede realizar revise la cantidad y que no se duplique la forma de pago`, "danger", 6000, "bottom-right",)
              return false
            }
            this.addPaymentClient(payload);
            this.addPaymentClientList(this.cart.cliente.paymentList);
            this.balancePayCredit(formaPagoNombre);
          }
        }



        //Metodo de pago para saldo a favor
        if (formaPagoNombre.credittouse=="1" && parseFloat(this.cart.cliente.credit) != 0) {
          if (
            parseFloat(this.cart.cliente.credit) >
            parseFloat(this.totalBalance) ||
            parseFloat(this.paymentFormat.total) > parseFloat(this.totalBalance)
          ) {
            saldoCr = parseFloat(this.cart.cliente.credit) - parseFloat(this.totalBalance);
            if (this.cart.cliente.paymentList.length >= 1) {
              payload = {
                id: formaPagoNombre.id,
                name: formaPagoNombre.name,
                amount: this.paymentFormat.total,
                reference: this.paymentFormat.reference,
                order: formaPagoNombre.principal ? 1 : 0,
                credittouse:formaPagoNombre.credittouse
              }

              this.addPaymentClientList(this.cart.cliente.paymentList);
              this.balancePayCredit(formaPagoNombre);
            }
          } else {
            payload = {
              id: formaPagoNombre.id,
              name: formaPagoNombre.name,
              amount: this.cart.cliente.credit,
              reference: this.paymentFormat.reference,
              order: formaPagoNombre.principal ? 1 : 0,
              credittouse:formaPagoNombre.credittouse
            };

            this.addPaymentClient(payload);
            this.addPaymentClientList(this.cart.cliente.paymentList);
            this.balancePayCredit(formaPagoNombre);

          }
        } else if(formaPagoNombre.credittouse=='1' ) {
          if ( this.cart.cliente.paymentList.length >= 1 && parseFloat(this.cart.cliente.credit) == 0.0) showAlertMessage(`Alerta`, "BellIcon", `😡 No cuenta con credito disponible, favor de seleccionar otra forma de pago`, "danger", 6000, "bottom-right",)
          this.disablePay = false
        }

        //Metodo de pago para resort credit
        if ( formaPagoNombre.credittouse=="2" && parseFloat(this.cart.cliente.resortCredit) > 0 &&  this.paymentFormat.total!=0 )  {
          console.log('entró a pagar como resort credit')

          if ( parseFloat(this.cart.cliente.resortCredit) >= parseFloat(this.totalBalance) ) {
            payload = {
              id: formaPagoNombre.id,
              name: formaPagoNombre.name,
              amount: this.paymentFormat.total,
              reference: this.paymentFormat.reference,
              order: formaPagoNombre.principal ? 1 : 0,
              credittouse: formaPagoNombre.credittouse
            };
          } else {
            payload = {
              id: formaPagoNombre.id,
              name: formaPagoNombre.name,
              amount: parseFloat(this.cart.cliente.resortCredit).toFixed(2),
              reference: this.paymentFormat.reference,
              order: formaPagoNombre.principal ? 1 : 0,
              credittouse: formaPagoNombre.credittouse
            };
          }
          this.addPaymentClient(payload);
          this.addPaymentClientList(this.cart.cliente.paymentList);
          this.balancePayCredit(formaPagoNombre)

        }  else if(formaPagoNombre.credittouse=="2" ) {
          console.log('intentó pagar como resort credit');
          showAlertMessage(`Alerta`, "BellIcon", `😡 No cuenta con credito disponible, favor de seleccionar otra forma de pago`, "danger", 6000, "bottom-right",)
          this.disablePay = false;
        }

        //Metodo de pago benefit
        if (formaPagoNombre.type=="3" ){
          // Verifico si ya existe el método de pago
          validFormaPagoId = this.cart.cliente.paymentList.find((fp) => fp.id === this.paymentFormat.id)
          if (validFormaPagoId){
            showAlertMessage(`Alerta`, "BellIcon", `No se puede duplicar esta forma de pago`, "warning", 6000, "bottom-right",)
            this.disablePay = false
          }

          else {
            if(parseFloat(this.paymentFormat.total) === 0 )  {
              payload = {
                id: formaPagoNombre.id,
                name: formaPagoNombre.name,
                amount:  this.paymentFormat.total,
                reference: this.paymentFormat.reference,
                order: formaPagoNombre.principal ? 1 : 0,
                credittouse:formaPagoNombre.credittouse
              }
              this.addPaymentClient(payload);
              this.addPaymentClientList(this.cart.cliente.paymentList)
              this.balancePayCredit(formaPagoNombre)
            } else {
              showAlertMessage(`Alerta`, "BellIcon", `😡 No puede pagar si el saldo no es 0, favor de seleccionar otra forma de pago`, "danger", 6000, "bottom-right",)
              this.disablePay = false
            }
          }
        }
      }
      this.paymentFormat.total = this.totalBalance;
      this.paymentFormat.reference = ""; //al agregar la forma de pago se resetea la reference
      // this.deleteTerminal()
    },
    // finaliza sendPayFormat
    deletePay(id) {
      const formaPagoNombre = this.user.fp.find((fp) => fp.id === id)
      const hasManyPaymentMethods = this.cart.cliente.paymentList.length > 0

      if (formaPagoNombre?.isownersPayment) this.resetBenefit()

      this.deletePaymentMethodClient(formaPagoNombre)
      this.paymentFormat.total = this.totalBalance
      this.disablePay = false
      this.paymentFormat.cc_bank = ""
      this.paymentFormat.auth_bank = ""
      this.paymentFormat.cc_type_bank = ""
      // actualizo el id de paymentFormat para que se mantenga una forma de pago seleccionada en el input Método de pago*
      this.setIdPaymentFormat()
    },
    resetRoomChargeData(){
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.unsetMakeRoomCharge()
    },

    // # Descuento de beneficios
    async sendInfo(item, housingid) {
      this.$root.$emit("bv::hide::modal", "modal-owners")
      const contracts = await this.getContractOwner({idHousing: housingid})
      if (contracts?.length > 0) {
        let itemJson=toJson(...contracts)
        const payload = {
          owner: item,
          contracts: contracts,
        }
        this.setCurrentContract(itemJson)
        this.setContract(payload)
        this.isOwner=true
        const client = await this.checkIfClientExists(this.cart.cliente)
        this.setInfoClient(client)
        this.setHotelCliente(itemJson.resortsid)

        this.cart.contractId=itemJson.id
        await this.getdiscountsPerContract(itemJson.id)
        await this.sendInfoMemberDescount(itemJson.id)
        // await this.useBenefit(null)

        // await this.useBenefit()
      } else showAlertMessage("No se encontró un contrato válido","InfoIcon","Por favor, revise si existe un contrato vigente asignado al propietario seleccionado.","warning", 4000 ,"bottom-right")
    },
    async sendInfoMemberDescount(id) { // Descuentos de membresía
      await this.getdiscountsPerContract(id)

      this.setDescountMember()
      await this.calculateTotalWithDiscounts()

      this.paymentFormat.total = this.totalBalance
    },
    async setDiscountsPerCategory(category){
      const discounts = toJson(this.avaiableDiscounts)
      discounts.filter(pr => !!pr.applicable && category == pr.category).forEach(item => {
        this.setDescountMemberDirect(item.uuid)
      })
      await this.calculateTotalWithDiscounts()

      this.paymentFormat.total = this.totalBalance
    },
    async useBenefit(item) { // Descuento de Beneficios
      const formaPagoNombre = this.user.fp.find((fp) => fp.isownersPayment === true)
      if (formaPagoNombre){
        if (this.cart.benefits.length == 0 ) await this.getdiscountsPerContract(this.cart.contractId)
        const ownerBooking = this.cart.items.some(prod=>prod.categoryName == 'ReservasOwners')

        if(this.cart.benefits.length > 0 ){
          if (this.cart.benefits.length == 1 && this.cart.benefits.some(item=>item.benfitcode=="RCOH") && !ownerBooking) showAlertMessage("No se encontraron beneficios aplicables", "InfoIcon" , "Se procederá a usar los beneficios por membresía aplicables.", "warning", 4000 ,"bottom-right")

        } else if (!ownerBooking) showAlertMessage("No se encontraron beneficios aplicables", "InfoIcon" , "Se procederá a usar los beneficios por membresía aplicables.", "warning", 4000 ,"bottom-right")

        const descountsCalculated = await this.benefitDiscountManager({
          contractId: this.cart.contractId,
          products: this.cart.items.filter(pr=>pr.categoryName != "ReservasOwners")
        })
        if (!descountsCalculated) return
        await this.calculateTotalWithDiscounts()
        this.paymentFormat.total = structuredClone(this.totalBalance)

        if (this.listBenefitUsed.length > 0 || ownerBooking) {
          const  paymentPayload = {
            id: formaPagoNombre.id,
            name: formaPagoNombre.name,
            amount: "0.00",
            reference: this.paymentFormat.reference,
            order: 0,
          }
          const validFormaPagoId = this.cart?.cliente?.paymentList
          const pagoIdvalid = validFormaPagoId?.length > 0 ? validFormaPagoId?.find((fp) => fp.id === formaPagoNombre?.id) : undefined
          if (pagoIdvalid == undefined){
            this.addPaymentClient(paymentPayload)
            this.addPaymentClientList(this.cart.cliente.paymentList)
            console.log('from add payment client', this.totalBalance)
            this.paymentFormat.total = structuredClone(this.totalBalance)
            this.paymentFormat.reference = ""; //al agregar la forma de pago se resetea la reference
            showAlertMessage("Ok: Se aplicaron los Beneficios","InfoIcon","Favor de revisar.","success", 4000 ,"bottom-right")
          } else {
            const idx = validFormaPagoId.findIndex((fp) => fp.id === formaPagoNombre?.id)
            this.cart.cliente.paymentList[idx].reference = structuredClone(this.paymentFormat.reference)
            this.addPaymentClientList(this.cart.cliente.paymentList)
            this.paymentFormat.reference = ""; //al agregar la forma de pago se resetea la reference
          }

        } // else showAlertMessage("No se encontró información 😞","InfoIcon","No hay beneficios por aplicar, favor de revisar.","danger",4000,"bottom-right")

      } else showAlertMessage("No se encontró una forma de pago válida para aplicar beneficios","InfoIcon","Favor de revisar si cuenta con un tipo de pago para propietarios.","warning", 4000 ,"bottom-right")
    },

    async getdiscountsPerContract(contract){
      if (!!contract) {
        const currentBenefits = await this.fetchBenefitsByYearContract({idContract: contract, year: new Date().getFullYear()})
        this.setCurrentContractBenefitsList(currentBenefits)
        const data = {
          productsDetail: formatProducList( this.cart.items ),
          contractId:contract,
        }

        const benefits = await this.getdiscountsContract(data)

        this.setMembershipsDiscount(benefits?.membershipDiscount || [])
        const payload = {
          contractId:   contract,
          benefitsInfo: benefits.benfitsDiscounts
        }
        this.setBenefits(payload)
        this.setResortCreditOne()
      }
    },
    useBenefitCredit() {
      this.setResortCreditOne();
    },
    resetBenefit(){
      this.listBenefitUsed?.forEach(item => {
        this.$refs['benefitCard'].deleteDescount(item)
      })
    },
    resetBenefitMember(){
      this.listMemberBenefitUsed?.forEach(item => {
        this.$refs['benefitCard'].deleteDescountMember(item)
      })
    },
    async sendInfoSelect() {
      const item = this.ownersInfo
      const housingid = this.owners.housingid
      const contracts = await this.getContractOwner({idHousing: housingid})
      const payload = {
        owner: item,
        contracts: contracts,
      }
      this.setContract(payload)
      this.$root.$emit("bv::hide::modal", "modal-owners");
    },
    checkIfAlreadyHasAContract(client){
      if (this.cart.currentContract) {
        const currentUserContract = this.cart.currentContract?.owners.some(owner => owner?.idOwner === client?.id)
        // console.log(currentUserContract);
        return currentUserContract
      } else return false
    },

    // # Modales
    openModalQuotes() {
      this.listDescriLoad()
      this.quoted = {
        idWelcom: "",
        idFired: "",
        termsId: "",
        idAmenities: "",
        dataListcontentFired: "",
        dataListcontent: "",
        dataListcontentTerms: "",
        dataListcontentAmenities: ""
      }
    },
    openModalAuthorizeBooking() {
      const modal = "authorize-booking";
      const btnShowBooking = "btnShowBooking";
      this.$root.$emit("bv::show::modal", modal, btnShowBooking);
    },
    openModalOwner() {
      this.$root.$emit("bv::show::modal", "modal-owners")
    },
    openModalBenefit() {
      this.setBenefits([])
      this.$root.$emit("bv::show::modal", "modal-benefit")
    },

    // # Quotes
    async listDescriLoad() {
      const itemFind = this.cart.items.find((category) => category.categoryName === "Reservas")

      if (itemFind != undefined) this.quoted.isRoom = true
      else this.quoted.isRoom = false

      await this.fetchListDescriQuoted({ idLanguage: this.cart.cliente.language, typeTitle: 1 })
      await this.fetchListDescriQuotedFired({ idLanguage: this.cart.cliente.language, typeTitle: 2 })
      await this.fetchsetListDescriTerms({ idLanguage: this.cart.cliente.language, typeTerms: 2 })
      await this.fetchsetListDescriAmenities({idLanguage: this.cart.cliente.language, idHotel: this.cart.cliente.hotel})
      this.setValidNull()

      if (this.validQuoted != true) this.$root.$emit("bv::show::modal", "modal-quote")
      else showAlertMessage("No se encontró información 😞","InfoIcon","Por favor, revise los catálogos de textos de bienvenida y despedida o amenidades.","danger",4000,"bottom-right")
    },

    // # Funcionalidades
    validPayRoom() {
      const idFormaPago = this.paymentFormat.id;
      const paymentMethod = this.user.fp.find((fp) => fp.id === idFormaPago)
      const { unicopago } = paymentMethod
      if (unicopago == true || this.cart.firstPay != 0) this.disablePay = true
      else this.disablePay = false
      // if (this.canUseBenefit) this.useBenefit(this.cart.contract)
    },
    async setTipoClienteInfo(customertype) {
      this.setOwners(null)
      this.setContract("")
      if (this.cart.cliente.customertype != 2  && !!this.cart.currentContract) {
        this.setCurrentContractBenefitsList([])
        this.setCurrentContract(null)
        this.resetAllBenefitsProductsDiscounts()
      }
      if(this.cart.cliente.customertype==2 || this.isOwner==true) {
        this.setInfoClient(null)
        this.clearHeadersCliente()
        this.clearOwnerInfo()
      }
      this.setTipoCliente(customertype)
      await this.calculateTotalWithDiscounts()
      this.paymentFormat.total = this.totalBalance
    },
    resetCustomerForm() {
      this.$refs.formularioCliente.reset(); //reseteo validacion veevalidates
      this.paymentFormat.id = "";
    },
    deleteTerminal() {
      this.paymentFormat.cc_bank = "";
      this.paymentFormat.auth_bank = "";
      this.paymentFormat.cc_type_bank = "";
    },
    formatPhoneNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      //43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
      const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
      const respuesta = codesAllowed.includes(keyCode);
      if (!respuesta) event.preventDefault()
    },
    setFiles(item){
      this.addFiles(item)
      this.$root.$emit("bv::hide::modal", "modal-files");
    },
    resetFiles(){
      this.deleteFiles()
      this.$root.$emit("bv::hide::modal", "modal-files");
    },
    checkIfEpayment(){
      // valido si existe una reserva, si es pago parcial y si no está pagando todas las noches
      const isPartial = this.cart?.items?.find(item => item?.partial)
      const validPartial = isPartial?.nightsNum != isPartial?.nights
      // valido el método de pago actual
      const paymentMethod = this.user.fp.find((fp) => fp.id == this.paymentFormat.id)
      // valido que el cliente no sea un propietario
      const noOwners = this.cart?.cliente?.customertype != 2
      // Valido si el pago se hace en dólares, si el método de pago activa el epayment y si la reserva no viene por parte de Fives Club
      const valid = this.cart.cliente.currency.code === 'USD' && validPartial && paymentMethod?.ePayment && noOwners
      this.setEpayment(valid)
    },
    resetpayment(){
      this.paymentFormat.id=''
      this.paymentFormat.total = (Math.round(this.cart.totalBreakdown.total * 100) / 100).toFixed(2)
      this.paymentFormat.total = this.totalBalance
    },
    evalua() {
      if ((this.paymentFormat.id == formaPagoNombre.credittouse) !== 0) this.paymentFormat.total = this.cart.cliente.credit
      else this.paymentFormat.total = this.totalBalance
    },
    copyDataReservation() {
      this.copyDataMainItem(this.cart.items)
    },
    async resetEveryBenefitDiscounts(){
      this.resetAllBenefitsProductsDiscounts()
      await this.calculateTotalWithDiscounts(this.product)
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~vue-form-wizard/dist/vue-form-wizard.min.css";
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
.totalParcial {
  color: crimson;
}
button.btn.btn-info[data-v-e920eb32] {
  background-color: #2f7d3f !important;
}
.buttonOwner {
  font-size: 10px;
  padding: 5px 7px 7px 5px;
}
.toLeft{
  padding-right: 1rem;
}
.mid-buttons{
  padding: 0.75rem;
}
.custom-margin{
  margin: 0 0.5rem;
}
.margin-list{
  margin-top: 2rem !important
}
</style>


